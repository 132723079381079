<template lang="pug">
  div#app
    router-view
</template>

<script>
export default {
  name: 'App'
}

</script>
