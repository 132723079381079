import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueScrollTo from 'vue-scrollto'
import './assets/styles/style.sass'
// import vueHeadful from 'vue-headful'
// // eslint-disable-next-line
// Vue.component('vue-headful', vueHeadful)

Vue.use(VueScrollTo, {
  container: 'body',
  duration: 100,
  easing: 'linear',
  offset: -86,
  force: true,
  onCancel: false,
  x: false
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
